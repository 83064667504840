import React, { useEffect, ChangeEventHandler, useState, useRef } from "react";
import styled from "styled-components";
import '../styles/inputs.css';
import DestinationInput from "../components/inputs/DestinationInput"
import DateRangePicker from "../components/inputs/DateRangePicker"
import RestaurantsCheckbox from "../components/inputs/RestaurantsCheckbox"
import TripTypes from "./inputs/TripTypes"
import DietaryPreferences from "./inputs/DietaryPreferences";
import PickInterest from "./inputs/PickInterest";
import { Button, Card, Nav } from "react-bootstrap";
import AddToLikeInput from "./inputs/AddToLikeInput";
import TimePicker from "./inputs/TimePicker";


function HomepageInputs(props) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        props.setFlightDetail(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="trip-inputs-container">
                <DestinationInput
                    destination={props.destination}
                    setDestination={props.setDestination}
                />
                <DateRangePicker
                    fromValue={props.fromValue}
                    setFromValue={props.setFromValue}
                    toValue={props.toValue}
                    setToValue={props.setToValue}
                />
                <RadioGroup
                    label="Do you want to add start and end time in your itinerary?"
                    name="addTimePicker"
                    value={props.flightDetail.addTimePicker}
                    onChange={handleInputChange}
                />
                {
                    props.flightDetail.addTimePicker === "yes" && (
                    <TimePicker
                        time={props.time}
                        setTime={props.setTime}
                        fromValue={props.fromValue}
                        toValue={props.toValue}
                    />
                    )
                }
                <TripTypes
                    tripType={props.tripType}
                    setTripType={props.setTripType}
                />
                {/* <RestaurantsCheckbox
                    isRestaurants={props.isRestaurants}
                    setIsRestaurants={props.setIsRestaurants}
                /> */}
                {
                    props.isRestaurants && (
                        <DietaryPreferences
                            dietaryPreferences={props.dietaryPreferences}
                            setDietaryPreferences={props.setDietaryPreferences}
                        />
                    )
                }
                {props.filterLoading && (
                    <div className="loader"></div>
                )
                }
                {!!props.interest?.length && (
                    <PickInterest
                        interest={props.interest}
                        setInterest={props.setInterest}
                    />
                )}
                <AddToLikeInput
                    anyThingElse={props.anyThingElse}
                    setAnyThingElse={props.setAnyThingElse}
                />
                <div className="my-3">
                    <RadioGroup
                        label="Do you have a flight booked?"
                        name="flightBooked"
                        value={props.flightDetail.flightBooked}
                        onChange={handleInputChange}
                    />
                    {/* {props.flightDetail.flightBooked === "yes" && (
                        <DateTimePickerExample
                            arrivalDate={props.arrivalDate}
                            setArrivalDate={props.setArrivalDate}
                            departureDate={props.departureDate}
                            setDepartureDate={props.setDepartureDate}
                        />
                    )} */}
                    <RadioGroup
                        label="Do you want to rent a car?"
                        name="rentCar"
                        value={props.flightDetail.rentCar}
                        onChange={handleInputChange}
                    />

                    {props.flightDetail.rentCar === 'no' && (
                        <>
                            <RadioGroup
                                label="Do you need a transfer from the airport?"
                                name="transferAirport"
                                value={props.flightDetail.transferAirport}
                                onChange={handleInputChange}
                            />
                        </>
                    )}
                </div>
            </div>
        </>


    )
}

export default HomepageInputs;


const RadioGroup = ({ label, name, value, onChange }) => (
    <div className="d-flex flex-column flex-lg-row align-items-center my-2 radio-container">
        <span className="pe-3 min-width-md">{label}</span>
        <div className='d-flex align-items-center gap-1'>
            <input
                type="radio"
                id={`${name}Yes`}
                value="yes"
                checked={value === 'yes'}
                onChange={onChange}
                className="radio-input"
                name={name}
            />
            <label htmlFor={`${name}Yes`} className="p-1 pe-3 radio-custom">Yes</label>

            <input
                type="radio"
                id={`${name}No`}
                value="no"
                checked={value === 'no'}
                onChange={onChange}
                className="radio-input"
                name={name}
            />
            <label htmlFor={`${name}No`} className="p-1 radio-custom">No</label>
        </div>
    </div>
);







